// import logo from "./logo.svg";
import FeatureArea from "./components/feature/featureArea";
import Header from "./components/header/Header";
import { mainbackgroundColor } from "./components/Colors";
function App() {
  return (
    <div
      style={{
        margin: 0,
        padding: 0,
        backgroundColor: `${mainbackgroundColor}`,
      }}
      className="App"
    >
      <Header />
      {/* <HeroArea /> */}
      <FeatureArea />
      {/* black hat studios */}
    </div>
  );
}

export default App;
